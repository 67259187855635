<template>
  <v-col md="10" offset-md="2">
    <router-view />
    <dashboard-core-footer />
  </v-col>
</template>

<script>
export default {
  name: "DashboardCoreView",

  components: {
    DashboardCoreFooter: () => import("./Footer"),
  },
};
</script>
